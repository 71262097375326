import React, {useEffect, useState} from "react";
import CommentService from "../services/comment.service";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export default function AddComment(props) {
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(props.buttonDisabled);
    const [subjectId, setSubjectId] = useState(props.subjectId);
    const [interactionId, setInteractionId] = useState(props.interactionId);
    const [subjectName, setSubjectName] = useState(props.subjectName);
    const [message, setMessage] = useState("");
    const [form, setForm] = useState({});
    const [checkBtn, setCheckBtn] = useState({});

    // Аналогично componentDidMount и componentDidUpdate:
    useEffect(() => {
        setSubjectId(props.subjectId)
        setSubjectName(props.subjectName)
        setButtonDisabled(props.subjectName && comment ? false : true)
    });

    function onChangeComment(e) {
        setComment(e.target.value);
    }

    function handleCommentAdd(e) {
        e.preventDefault();

        setMessage("");
        setLoading(true)

        form.validateAll();

        if (checkBtn.context._errors.length === 0) {
            CommentService.addComment(subjectId, interactionId, comment).then(
                () => {
                    window.location.reload();
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setLoading(false)
                }
            );
        } else {
            setLoading(false)
        }
    }

    return (
        <div>
            <h3>Add comment for {subjectName}</h3>
            <Form
                onSubmit={handleCommentAdd}
                ref={c => {
                    setForm(c);
                }}
            >
                <div className="form-group">
                    <label htmlFor="comment">Comment</label>
                    <Input
                        type="text"
                        className="form-control"
                        name="comment"
                        value={comment}
                        onChange={onChangeComment}
                        validations={[required]}
                    />
                </div>

                <div className="form-group">
                    <button
                        className="btn btn-primary btn-block"
                        disabled={loading || buttonDisabled}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Add comment</span>
                    </button>
                </div>

                {message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                    </div>
                )}
                <CheckButton
                    style={{display: "none"}}
                    ref={c => {
                        setCheckBtn(c);
                    }}
                />
            </Form>
        </div>
    );
}