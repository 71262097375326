import React, {useEffect, useState} from "react";
import ParameterService from "../services/parameter.service";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";

export default function AddParameter(props) {
    const [parameterName, setParameterName] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);


    function handleParameterAdd(e) {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        setButtonDisabled(true);


        ParameterService.addParameter(parameterName).then(
            () => {
                window.location.reload();
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    function onChangeParameterName(e) {
        setParameterName(e.target.value);
    }

    useEffect(() => {
        setButtonDisabled(parameterName.trim() ? false : true);
    }, [parameterName]);

    return (
        <div>
            <h3>Add new parameter</h3>
            <Form onSubmit={handleParameterAdd}>
                <div className="form-group">
                    <label htmlFor="parameterName">Parameter name</label>
                    <Input
                        type="text"
                        className="form-control"
                        name="parameterName"
                        value={parameterName}
                        onChange={onChangeParameterName}
                    />
                </div>

                <div className="form-group">
                    <button
                        className="btn btn-primary btn-block"
                        disabled={loading | buttonDisabled}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Add parameter</span>
                    </button>
                </div>

                {message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
}
