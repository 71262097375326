import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class StatisticsService {
    getAvgByParameterForSubjectCU(parameter) {
        return axios.get(API_URL + 'atomic-feedback/personal-statistics', {
            headers: authHeader(),
            params: {parameter_name: parameter}
        });
    }

    getAvgByParameterForSubject(parameter, subject_id) {
        return axios.get(API_URL + 'atomic-feedback/global-statistics', {
            headers: authHeader(),
            params: {parameter_name: parameter, subject_id: subject_id}
        });
    }
}

export default new StatisticsService();
