import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class UserService {
    getActiveParticipants() {
        return axios.get(API_URL + 'participant', {headers: authHeader()});
    }

    approveParticipant(participant_id) {
        return axios
            .put(API_URL + "participant/approve", {
                participant_id
            }, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }
}

export default new UserService();
