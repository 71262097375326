import React, {useEffect, useState} from "react";
import TenantService from "../services/tenant.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

export default function Tenant(props) {
    const [companyName, setCompanyName] = useState("");
    const [userSelfRegistrationEnabled, setUserSelfRegistrationEnabled] = useState(false);
    const [emailDomain, setEmailDomain] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    useEffect(() => {
        TenantService.getTenantConfiguration().then(
            response => {
                setCompanyName(response.data.companyName);
                setUserSelfRegistrationEnabled(response.data.userSelfRegistrationEnabled);
                setEmailDomain(response.data.emailDomain);
            },
            error => {
                setCompanyName((error.response && error.response.data) ||
                    error.message ||
                    error.toString());
            }
        );
    }, []);

    function handleSaveTenantConfig(e) {
        //alert(JSON.stringify(e.target.checked));
        e.preventDefault();
        setLoading(true);
        setMessage("");

        TenantService.updateTenantConfiguration(companyName, userSelfRegistrationEnabled, emailDomain).then(
            () => {
                setLoading(false);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    function onChangeEmailDomain(e) {
        setEmailDomain(e.target.value);
    }

    function onChangeUserSelfRegistrationEnabled(e) {
        setUserSelfRegistrationEnabled(e.target.checked);
    }

    return (
        <div className="container">
            <div>
                <header className="jumbotron">
                    <h3>
                        <strong>Current tenant ID</strong> Configuration
                    </h3>
                </header>
                <p>
                    <strong>Tenant ID:</strong>{" "}
                    bla-bla-bla
                </p>
                <Form onSubmit={handleSaveTenantConfig}>
                    <p>
                        <strong>Company name:</strong>{" "}
                        {companyName}
                    </p>
                    <p>
                        <strong>Enable users self registration:</strong>
                        <Input
                            type="checkbox"
                            className="form-control"
                            name="userSelfRegistrationEnabled"
                            checked={userSelfRegistrationEnabled}
                            onChange={onChangeUserSelfRegistrationEnabled}
                        />
                        {userSelfRegistrationEnabled}
                    </p>

                    <p>
                        <strong>Email domain:</strong>{" "}
                        <Input
                            type="text"
                            className="form-control"
                            name="emailDomain"
                            value={emailDomain}
                            onChange={onChangeEmailDomain}
                            disabled={!userSelfRegistrationEnabled}
                        />
                    </p>
                    <p>
                        <button
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Save tenant configuration</span>
                        </button>
                    </p>
                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
}
