import React, {useEffect, useState} from "react";
import UserService from "../services/user.service";
import Table from "react-bootstrap/Table";

export default function Users() {
    const [users, setUsers] = useState([]);


    function updateUsers() {
        UserService.getActiveParticipants().then(
            response => {
                setUsers(response.data);
            },
            error => {
                setUsers((error.response && error.response.data) ||
                    error.message ||
                    error.toString());
            }
        );
    }

    useEffect(() => {
        updateUsers();
    }, []);

    function handleApproveAction(participantId) {
        UserService.approveParticipant(participantId).then(
            () => {
                updateUsers();
            }
        )
    }

    return (
        <div className="container">
            <header className="jumbotron">
                <h3>Users</h3>
                <Table responsive="sm">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.first_name} {user.last_name}</td>
                            <td>{user.email}</td>
                            <td>{user.email_confirmed ? <span>confirmed</span> :
                                <span className="cursorPointer"
                                      onClick={() => handleApproveAction(user.id)}>approve</span>}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </header>
        </div>
    );
}
