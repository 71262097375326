import React, {useEffect, useState} from "react";
import ParameterService from "../services/parameter.service";
import Table from "react-bootstrap/Table";
import AddParameter from "./add-parameter.component";

export default function Parameters(props) {
    const [parameters, setParameters] = useState([]);
    const [message, setMessage] = useState("");

    function handleParameterDelete(parameter) {
        ParameterService.deleteParameter(parameter).then(
            () => {
                fetchParameters();
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.error(resMessage);
            }
        );
    }

    function fetchParameters() {
        ParameterService.getParameters().then(
            response => {
                setParameters(response.data);
            },
            error => {
                setMessage((error.response && error.response.data) || error.message || error.toString());
            }
        );
    }

    useEffect(() => {
        fetchParameters();
    }, []);

    return (
        <div className="container">
            <header className="jumbotron">
                <h3>Parameters</h3>
                <Table responsive="sm">
                    <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {parameters.map(parameter => (
                        <tr key={parameter}>
                            <td>{parameter}</td>
                            <td><a className="cursorPointer" key={parameter}
                                   onClick={() => handleParameterDelete(parameter)}>delete</a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <AddParameter/>
            </header>
        </div>
    );
}
