import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class AuthService {
  login(username, password) {
    return axios
        .post(API_URL + "login", {
          username,
          password
        })
        .then(response => {
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }

          return response.data;
        });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("tenant");
  }

  register(email, password) {
    return axios.post(API_URL + "signup", {
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();
