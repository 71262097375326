import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class InteractionService {
    getInteractions() {
        return axios.get(API_URL + 'interaction', {headers: authHeader()});
    }

    getInteraction(interactionId) {
        return axios.get(API_URL + 'interaction/' + interactionId, {headers: authHeader()});
    }

    addInteraction(interactionName) {
        return axios
            .post(API_URL + "interaction", {
                interactionName
            }, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }

    removeParticipantsFromInteraction(interactionId, participantIds) {
        return axios
            .post(API_URL + "interaction/remove-participants", {
                interactionId,
                participantIds
            }, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }

    getParticipants(interactionId) {
        return axios.get(API_URL + 'interaction/participants/' + interactionId,
            {headers: authHeader()});
    }
}

export default new InteractionService();
