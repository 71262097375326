import React, {useEffect, useState} from "react";
import StatisticsService from "../services/statistics.service";

export default function GlobalStatisticsItem(props) {
    const subjectId = useState(props.subjectId)[0];
    const parameterName = useState(props.parameterName)[0];
    const [avg, setAvg] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        StatisticsService.getAvgByParameterForSubject(parameterName, subjectId).then(
            statResponse => {
                setAvg(statResponse.data.avg);
                setCount(statResponse.data.count);
            }
        )
    });

    return (
        <div className="container">
            a:{avg} c:{count}
        </div>
    );
}