import React, {useEffect, useState} from "react";
import InteractionService from "../services/interaction.service";
import AddInteraction from "./add-interaction.component";

export default function Home() {
    const [interactions, setInteractions] = useState([]);


    useEffect(() => {
        InteractionService.getInteractions().then(
            response => {
                setInteractions(response.data);
            },
            error => {
                setInteractions((error.response && error.response.data) ||
                    error.message ||
                    error.toString());
            }
        );
    }, []);

    return (
        <div className="container">
            <header className="jumbotron">
                <h3>Select interaction</h3>
                {interactions.map(interaction => (
                    <p key={interaction.id}>{interaction.timestamp} <a href={"interaction/" + interaction.id}
                                                                       key={interaction.id}>{interaction.name}</a>
                    </p>
                ))}
                <AddInteraction/>
            </header>
        </div>
    );
}
