import React, {Component} from "react";
import ParameterService from "../services/parameter.service";
import Table from "react-bootstrap/Table";
import StatisticsService from "../services/statistics.service";
import CommentService from "../services/comment.service";


export default class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interactionId: String(props.match.params["id"]),
            parameters: [],
            statistics: {},
            comments: [],
            loading: false,
        };
    }

    componentDidMount() {
        ParameterService.getParameters().then(
            response => {
                this.setState({
                    parameters: response.data,
                    loading: false,
                });
                this.state.parameters.map((parameter, i) => {
                    StatisticsService.getAvgByParameterForSubjectCU(parameter).then(
                        statResponse => {
                            let stat = this.state.statistics
                            stat[parameter] = statResponse.data
                            this.setState({
                                statistics: stat
                            })
                        }
                    )
                });
            },
            error => {
                this.setState({
                    parameters:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString()
                });
            }
        );
        CommentService.listCommentsToMe().then(
            response => {
                this.setState({
                    comments: response.data,
                    loading: false,
                });
            }
        );
    }

    render() {
        return (
            <div className="container">
                <header className="jumbotron">
                    <h3>All time statistics for the current user</h3>
                    <Table responsive="sm">
                        <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Average</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.parameters.map(parameter => (
                            <tr key={parameter}>
                                <td>{parameter}</td>
                                <td>{this.state.statistics[parameter] ? this.state.statistics[parameter]["avg"] : ""}</td>
                                <td>{this.state.statistics[parameter] ? this.state.statistics[parameter]["count"] : ""}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <h3>Comments</h3>
                    <ul>
                        {this.state.comments.map(comment => (
                            <li key={comment.id}>
                                <b>{comment.authorName}</b>: {comment.text}
                            </li>
                        ))}
                    </ul>
                </header>
            </div>
        );
    }
}
