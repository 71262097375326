import React, {Component} from "react";
import ParameterService from "../services/parameter.service";
import InteractionService from "../services/interaction.service";
import Table from 'react-bootstrap/Table';
import AtomicFeedbackItem from "./atomic-feedback-item.component";
import AddParameter from "./add-parameter.component";
import AddComment from "./add-comment.component";
import CommentService from "../services/comment.service";


export default class Interaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interactionId: String(props.match.params["id"]),
            interactionName: "",
            parameters: [],
            participants: [],
            comments: []
        };
    }

    componentDidMount() {
        ParameterService.getParameters().then(
            response => {
                this.setState({
                    parameters: response.data
                })
                ;
            },
            error => {
                this.setState({
                    parameters:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString()
                });
            }
        );
        InteractionService.getInteractions().then(
            response => {
                this.setState({
                    interactions: response.data
                });
            },
            error => {
                this.setState({
                    interactions:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString()
                });
            }
        );
        InteractionService.getInteraction(this.state.interactionId).then(
            response => {
                this.setState({
                    interactionName: response.data.name
                });
            },
            error => {
                this.setState({
                    interactionName:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString()
                });
            }
        );
        InteractionService.getParticipants(this.state.interactionId).then(
            response => {
                this.setState({
                    participants: response.data
                })
                ;
            },
            error => {
                this.setState({
                    participants:
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString()
                });
            }
        );
        CommentService.listCommentsForInteraction(this.state.interactionId).then(
            response => {
                this.setState({
                    comments: response.data
                });
            }
        )
    }

    handleCommentClick(subjectId, subjectName) {
        this.setState({
            subjectId,
            subjectName
        })
    }

    handleRemoveParticipantsClick(participantIds) {
        InteractionService.removeParticipantsFromInteraction(this.state.interactionId, participantIds).then(
            response => {
                this.componentDidMount();
            }
        )
    }

    render() {
        return (
            <div className="container">
                <header className="jumbotron">
                    <h3>Evaluate your interaction {this.state.interactionName}</h3>
                    <Table responsive="sm">
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            {this.state.parameters.map(parameter => (
                                <th key={parameter}>{parameter}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.participants.map(participant => (
                            <tr key={participant.id}>
                                <td key={participant.id}>
                                    <div
                                        className="participantName">{participant.first_name} {participant.last_name}</div>
                                    <div className="commentButton cursorPointer"
                                         onClick={() => this.handleCommentClick(participant.id,
                                             participant.first_name + " " + participant.last_name)}>
                                        {this.state.comments.includes(participant.id) ? "comment added" : "add comment"}
                                    </div>
                                    <div className="removeParticipantButton cursorPointer"
                                         onClick={() =>
                                             this.handleRemoveParticipantsClick([participant.id])}>
                                        remove participant
                                    </div>
                                </td>
                                {this.state.parameters.map(parameter => (
                                    <td key={parameter}>
                                        <AtomicFeedbackItem
                                            interactionId={this.state.interactionId}
                                            subjectId={participant.id}
                                            parameterName={parameter}
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <AddComment subjectId={this.state.subjectId}
                                interactionId={this.state.interactionId}
                                buttonDisabled={true}
                                subjectName={this.state.subjectName}/>
                    <AddParameter/>
                </header>
            </div>
        );
    }
}
