import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class CommentService {
    getComments() {
        return axios.get(API_URL + 'parameter', {headers: authHeader()});
    }

    addComment(subject_id, interaction_id, text) {
        return axios
            .post(API_URL + "comment", {
                subject_id, interaction_id, text
            }, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }

    listCommentsToMe() {
        return axios.get(API_URL + 'comment/to-me', {headers: authHeader()});
    }

    listCommentsForInteraction(interaction_id) {
        return axios.get(API_URL + 'comment/for-interaction', {
            headers: authHeader(),
            params: {interaction_id}
        });
    }
}

export default new CommentService();
