import React, {useEffect, useState} from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import InteractionService from "../services/interaction.service";

export default function AddInteraction(props) {
    const [interactionName, setInteractionName] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);


    function handleInteractionAdd(e) {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        setButtonDisabled(true);

        InteractionService.addInteraction(interactionName).then(
            () => {
                setLoading(false);
                setInteractionName("");
                window.location.reload();
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    function onChangeInteractionName(e) {
        setInteractionName(e.target.value);
    }


    useEffect(() => {
        setButtonDisabled(interactionName.trim() ? false : true);
    }, [interactionName]);

    return (
        <div>
            <h3>Add interaction</h3>
            <Form onSubmit={handleInteractionAdd}>
                <div className="form-group">
                    <label htmlFor="interactionName">Interaction name</label>
                    <Input
                        type="text"
                        className="form-control"
                        name="interactionName"
                        value={interactionName}
                        onChange={onChangeInteractionName}
                    />
                </div>

                <div className="form-group">
                    <button
                        className="btn btn-primary btn-block"
                        disabled={loading | buttonDisabled}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Add interaction</span>
                    </button>
                </div>

                {message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
}
