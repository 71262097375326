import React, {useEffect, useState} from "react";
import ParameterService from "../services/parameter.service";
import UserService from "../services/user.service";
import Table from 'react-bootstrap/Table';
import GlobalStatisticsItem from "./global-statistics-item.component";

export default function GlobalStatistics() {

    const [parameters, setParameters] = useState([]);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        ParameterService.getParameters().then(
            response => {
                setParameters(response.data);
            },
            error => {
                setParameters((error.response && error.response.data) ||
                    error.message ||
                    error.toString());
            }
        );
        UserService.getActiveParticipants().then(
            response => {
                setParticipants(response.data);
            },
            error => {
                setParticipants((error.response && error.response.data) ||
                    error.message ||
                    error.toString());
            }
        );
    }, []);

    return (
        <div className="container">
            <header className="jumbotron">
                <h3>Global Statistics</h3>
                <Table responsive="sm">
                    <thead>
                    <tr>
                        <th>&nbsp;</th>
                        {parameters.map(parameter => (
                            <th key={parameter}>{parameter}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {participants.map(participant => (
                        <tr key={participant.id}>
                            <td key={participant.id}>
                                {participant.first_name} {participant.last_name}
                            </td>
                            {parameters.map(parameter => (
                                <td key={parameter}>
                                    <GlobalStatisticsItem
                                        subjectId={participant.id}
                                        parameterName={parameter}
                                    />
                                </td>
                            ))}
                        </tr>

                    ))}

                    </tbody>
                </Table>
            </header>
        </div>
    );
}
