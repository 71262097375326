import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class ParameterService {
    getParameters() {
        return axios.get(API_URL + 'parameter', {headers: authHeader()});
    }

    addParameter(parameterName) {
        return axios
            .post(API_URL + "parameter", {
                parameterName
            }, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }

    deleteParameter(parameterName) {
        return axios
            .delete(API_URL + "parameter?parameterName=" + parameterName,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }
}

export default new ParameterService();
