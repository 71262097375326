import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class TenantService {
    getTenantConfiguration() {
        return axios.get(API_URL + 'tenant', {headers: authHeader()})
            .then(response => {
                if (response.data.userSelfRegistrationEnabled) {
                    localStorage.setItem("tenant", JSON.stringify(response.data));
                }
                return response;
            });
    }

    updateTenantConfiguration(company_name, user_self_registration_enabled, email_domain) {
        return axios
            .post(API_URL + "tenant", {
                company_name, user_self_registration_enabled, email_domain
            }, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }

    getCurrentTenant() {
        return JSON.parse(localStorage.getItem('tenant'));
    }
}

export default new TenantService();
