import React, {useState} from "react";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";
import Home from "./components/home.component";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Profile from "./components/profile.component";
import Interaction from "./components/interaction.component";
import Parameters from "./components/parameters.component";
import Statistics from "./components/statistics.component";
import GlobalStatistics from "./components/global-statistics.component";
import Tenant from "./components/tenant.component";
import Users from "./components/users.component";

export default function App(props) {

    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    const [isAdministrator, setIsAdministrator] = useState(currentUser ? currentUser.roles.includes("Administrator") : false);

    function logOut() {
        AuthService.logout();
    }

    return (
        <div>
            <nav className="navbar navbar-expand navbar-dark bg-dark">
                <Link to={"/"} className="navbar-brand">
                    FeedDot
                </Link>
                <div className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <Link to={"/home"} className="nav-link">
                            Home
                        </Link>
                    </li>

                    {currentUser && (
                        <li className="nav-item">
                            <Link to={"/statistics"} className="nav-link">
                                Personal statistics
                            </Link>
                        </li>
                    )}
                    {currentUser && (
                        <li className="nav-item">
                            <Link to={"/global-statistics"} className="nav-link">
                                Global statistics
                            </Link>
                        </li>
                    )}
                    {isAdministrator && (
                        <li className="nav-item">
                            <Link to={"/parameters"} className="nav-link">
                                Parameters
                            </Link>
                        </li>
                    )}
                    {isAdministrator && (
                        <li className="nav-item">
                            <Link to={"/users"} className="nav-link">
                                Users
                            </Link>
                        </li>
                    )}


                </div>

                {currentUser ? (
                    <div className="navbar-nav ml-auto">
                        {isAdministrator && (
                            <li className="nav-item">
                                <Link to={"/tenant"} className="nav-link">
                                    Tenant
                                </Link>
                            </li>
                        )}
                        <li className="nav-item">
                            <Link to={"/profile"} className="nav-link">
                                {currentUser.username}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href="/login" className="nav-link" onClick={logOut}>
                                LogOut
                            </a>
                        </li>
                    </div>
                ) : (
                    <div className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to={"/login"} className="nav-link">
                                Login
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to={"/register"} className="nav-link">
                                Sign Up
                            </Link>
                        </li>
                    </div>
                )}
            </nav>

            <div className="container mt-3">
                <Switch>
                    <Route exact path={["/", "/home"]} component={Home}>
                        {!currentUser ? <Redirect to={{pathname: '/login'}}/> : <Home/>}
                    </Route>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/register" component={Register}/>
                    <Route exact path="/profile" component={Profile}/>
                    <Route exact path="/interaction/:id" component={Interaction}/>
                    <Route exact path="/parameters" component={Parameters}/>
                    <Route exact path="/statistics" component={Statistics}/>
                    <Route exact path="/global-statistics" component={GlobalStatistics}/>
                    <Route exact path="/tenant" component={Tenant}/>
                    <Route exact path="/users" component={Users}/>
                </Switch>
            </div>
        </div>
    );
}


