import React, {useEffect, useState} from "react";
import AtomicFeedbackService from "../services/atomic-feedback.service";

export default function AtomicFeedbackItem(props) {

    const [interactionId, setInteractionId] = useState(props.interactionId);
    const [subjectId, setSubjectId] = useState(props.subjectId);
    const [parameterName, setParameterName] = useState(props.parameterName);
    const [mark, setMark] = useState(0);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    function handleAtomicFeedbackAdd(mark) {
        setMessage("");
        setMark(mark);
        setLoading(true);

        AtomicFeedbackService.addAtomicFeedback(
            parameterName, subjectId, interactionId, mark).then(
            () => {
                //this.componentDidMount()
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    useEffect(() => {
        AtomicFeedbackService.getAtomicFeedback(parameterName, subjectId, interactionId).then(
            response => {
                setMark(response.data);
            },
            error => {
                setMark(0);
            }
        );
    }, []);

    return (
        <div className="container">
            {mark === 0 ? (
                <div className="atomicFeedbackButtonsContainer">
                    <span className="cursorPointer" onClick={() => handleAtomicFeedbackAdd(2)}>👎</span>
                    <span className="cursorPointer" onClick={() => handleAtomicFeedbackAdd(5)}>👊</span>
                    <span className="cursorPointer" onClick={() => handleAtomicFeedbackAdd(8)}>👍</span>
                    <span className="cursorPointer" onClick={() => handleAtomicFeedbackAdd(10)}>🔥</span>
                </div>
            ) : (<div>{mark}</div>)}
        </div>
    );
}
