import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

class AtomicFeedbackService {
    getAtomicFeedback(parameter_name, subject_id, interaction_id) {
        return axios.get(API_URL + 'atomic-feedback', {
            headers: authHeader(),
            params: {parameter_name: parameter_name, subject_id: subject_id, interaction_id: interaction_id}
        });
    }

    addAtomicFeedback(parameter_name, subject_id, interaction_id, mark) {
        return axios
            .post(API_URL + "atomic-feedback", {
                parameter_name, subject_id, interaction_id, mark
            }, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }
}

export default new AtomicFeedbackService();
